<template>
  <div class="refund">
    <mt-header :title="$t('refund')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <div class="wrap">
            <div class="title border-b">
              <span>{{ $t('contractNo') }}</span>
              <div>{{ item.applyId }}</div>
            </div>
            <div class="row">
              <div class="name">{{ $t('amount') }}：</div>
              <div>{{ item.amount | formatMoney }}</div>
            </div>
            <div class="row">
              <div class="name">{{ $t('status') }}：</div>
              <div v-if="item.status == 4">{{ $t('success') }}</div>
              <div v-else>{{ $t('processing') }}</div>
            </div>
          </div>
          <!-- status 2 可退款， 3 处理中， 4 成功 -->
          <div v-if="item.status == 2">
            <mt-button type="primary" @click="onRefund(item.orderId)">{{
              $t("refund")
            }}</mt-button>
          </div>
        </li>
      </ul>
    </div>
    <mt-popup
      class="refundPopup"
      v-model="popupVisible"
    >
      <div class="box">
        <div class="title">{{ $t('warmTips') }}</div>
        <div class="content">
          {{ $t('refundTips') }}
        </div>
        <div class="bottom">
          <mt-button type="primary" size="large" @click="popupVisible = false">{{
            $t("cancel")
          }}</mt-button>
          <mt-button type="primary" size="large" @click="onRefundClick">{{
            $t("confirm")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { getRefundInfo, refund } from '../../utils/api'
export default {
  name: 'refund',
  components: {
    callPhone
  },
  data() {
    return {
      list: [],
      popupVisible: false,
      id: '',
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.loadMore()
      this.$indicator.close();
    },
    async loadMore() {
      const formData = new FormData()
      formData.append('applyId', this.user.applyId)
      formData.append('accessToken', this.$store.state.accessToken)
      await this.$axios({
        method: 'post',
        url: getRefundInfo,
        data: formData
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.list = e.body
          }
        })
        .catch(() => {})
    },
    // 退款
    onRefund(id) {
      this.id = id
      this.popupVisible = true
    },
    async onRefundClick() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      const formData = new FormData()
      formData.append('orderId', this.id)
      await this.$axios({
        method: 'post',
        url: refund,
        data: formData,
      })
        .then((e) => {
          if (e.status.code == "000") {
            this.init()
            this.popupVisible = false
          } else {
            this.$toast(e.status.msg)
          }
        })
        .catch(() => {})
      this.$indicator.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.refund {
  background-color: #FBFBFF;
  ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .mint-button {
    font-size: 14px;
  }
  ul {
    flex-grow: 1;
    position: relative;
    li {
      display: block;
      padding: 10px 15px;
      text-decoration: none;
      border-top: 1px solid #EAECEC;
      border-bottom: 1px solid #EAECEC;
      margin-bottom: 30px;
      background: #fff;
      display: flex;
      align-items: center;
    }
    .wrap {
      flex: 1;
      border-radius: 10px;
    }
    .title {
      display: flex;
      align-items: center;
      height: 45px;
      color: #111111;
      font-size: 16px;
      img {
        width: 20px;
        margin: 0 15px;
      }
      div {
        color: #000;
        padding-left: 20px;
      }
    }
    .row {
      display: flex;
      height: 30px;
      color: #A8A8A8;
      font-size: 14px;
      font-weight: bold;
      align-items: center;
    }
  }
  .refundPopup {
    border-radius: 10px;
    overflow: hidden;
    .box {
      width: 260px;
      .title {
        text-align: center;
        border-bottom: 1px solid #E4E4E4;
        font-size: 13px;
        padding: 8px;
      }
      .content {
        font-size: 12px;
        padding: 20px 10px;
        text-align: center;
      }
      .bottom {
        border-top: 1px solid #E4E4E4;
        display: flex;
        .mint-button {
          height: 35px;
          background-color: white;
          color: #9B9B9B;
          font-size: 13px;
          border-radius: 0px;
          &:last-child {
            color: #209CE8;
            border-left: 1px solid #E4E4E4;
          }
        }
      }
    }
  }
}
</style>